import { css } from '@emotion/react';

const LanguageOptionLabel = ({ image, label, onClick = () => {} }) => {
  return (
    <div tw="flex items-center" onClick={onClick}>
      <div
        tw="rounded-full w-4 h-4"
        css={css`
          background: url(${image}) no-repeat center center;
          background-size: contain;
        `}
      />
      <div tw="ml-3">{label}</div>
    </div>
  );
};

LanguageOptionLabel.displayName = 'LanguageOptionLabel';

export default LanguageOptionLabel;
