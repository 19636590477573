import tw from 'twin.macro';

import LoggedAsUserBar from '@components/elements/LoggedAsUserBar';
import Footer from '@components/modules/Footer/Footer';
import Header from '@components/modules/Header/Header';
import { cssMerge } from '@utils/styleHelpers';

const DefaultLayout = ({ children, styles = {} }) => {
  const twStyle = cssMerge({
    defaultCss: tw`pt-8 pb-8 sm:pb-12 md:pb-16 lg:pb-24`,
    ...styles?.content,
  });

  const contentProps = {
    id: 'content',
    ...(twStyle ? { css: twStyle } : {}),
  };

  return (
    <div tw="flex flex-col min-h-screen">
      <div tw="sticky top-0 z-50 ">
        <LoggedAsUserBar />
        <Header />
      </div>
      <main {...contentProps}>{children}</main>
      <Footer />
    </div>
  );
};

DefaultLayout.displayName = 'DefaultLayout';

export default DefaultLayout;
