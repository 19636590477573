import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '@store/auth/auth.slice';
import tw from 'twin.macro';

import DisplayUpMd from '@components/elements/DisplayOn/DisplayUpMd';
import Icon from '@components/elements/Icon';
import Link from '@components/elements/Link';
import ROUTE_URLS from '@constants/routeUrls';
import {
  selectModuleConfigClientPanel,
  selectModuleMoneyBox,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import { checkImageExists } from '@utils/helpers';
import { cssMerge } from '@utils/styleHelpers';

const MoneyBoxBalance = ({ styles = {} }) => {
  const { t } = useTranslation();
  const { icon, shortName } = useAppConfigSelector(selectModuleMoneyBox);
  const { moneyBox = 0 } = useSelector(selectUser);
  const { allowClientSeeMoneyBox } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );
  const [isExistImage, setIsExistImage] = useState(false);

  useEffect(() => {
    let isSubscribed = true;

    checkImageExists(icon)
      .then(value => {
        isSubscribed ? setIsExistImage(value) : null;
      })
      .catch(() => {
        isSubscribed ? setIsExistImage(false) : null;
      });

    return () => {
      isSubscribed = false;
    };
  }, [icon]);

  if (!allowClientSeeMoneyBox) {
    return null;
  }

  const twLinkStyle = cssMerge({
    defaultCss: tw`px-0 py-2`,
    ...styles?.link,
  });

  return (
    <Link
      data-cy="nav__moneybox"
      href={ROUTE_URLS.MONEY_BOX}
      type="button"
      variant="outlined"
      styles={twLinkStyle}
    >
      <div tw="flex items-center">
        <div tw="pl-2.5 md:px-2.5 text-sm flex items-center font-normal">
          <div tw="flex items-center justify-center w-5 h-5 md:mr-2">
            {isExistImage ? (
              <div
                css={[
                  tw`w-full h-full bg-center bg-cover`,
                  `background-image: url(${icon});`,
                ]}
              />
            ) : (
              <Icon name="piggyBank" tw="fill-current" />
            )}
          </div>
          <DisplayUpMd>
            <span>
              {t(
                '$*components.header.myAccount.conditionMoneyBox',
                'Stan skarbonki'
              )}
            </span>
          </DisplayUpMd>
        </div>
        <div tw="px-2.5 max-w-[150px] truncate whitespace-normal md:(border-l border-gray-1)">{`${moneyBox} ${
          shortName || t('$*common.pointAbbreviation', 'pkt')
        }`}</div>
      </div>
    </Link>
  );
};

export default MoneyBoxBalance;
