import type { ReactNode } from 'react';

import useMediaQuery from '@hooks/useMediaQuery';
import { between, down, only, up } from '@utils/screens';

type Size = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type Props = {
  children: ReactNode;
  size?: Size | Size[];
  mode?: 'down' | 'only' | 'up' | 'between';
  defaultState?: boolean;
};

const DisplayOn = ({
  children,
  size = 'lg',
  mode = 'up',
  defaultState = false,
}: Props) => {
  const isModeBetween = mode === 'between';

  let mediaProps: [string, boolean] | [];

  if (isModeBetween && Array.isArray(size)) {
    const [min, max] = size;

    mediaProps = [between(min, max), defaultState];
  } else {
    const modeFn = isModeBetween
      ? up
      : {
          down,
          only,
          up,
        }[mode];

    mediaProps = [modeFn(size), defaultState];
  }

  const isScreenQuery = useMediaQuery(...mediaProps);

  if (isScreenQuery) {
    return children;
  }

  return null;
};

DisplayOn.displayName = 'DisplayOn';

export default DisplayOn;
