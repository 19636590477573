import type { ReactNode } from 'react';

import DisplayOn from './DisplayOn';

type Props = {
  children: ReactNode;
};

const DisplayDownXxl = ({ children }: Props) => {
  return (
    <DisplayOn size="xxl" mode="down" defaultState={true}>
      {children}
    </DisplayOn>
  );
};

DisplayDownXxl.displayName = 'DisplayDownXxl';

export default DisplayDownXxl;
