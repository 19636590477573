import dynamic from 'next/dynamic';

import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import tw from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

const Icon = ({ name = '', styles = {}, ...iconProps }) => {
  if (isEmpty(name)) {
    return null;
  }

  const iconName = upperFirst(`${name}Icon`);
  const DynamicIcon = dynamic(() => import(`@assets/icons/${iconName}`));

  const css = cssMerge({
    defaultCss: tw`w-4 h-4`,
    ...styles,
  });

  return <DynamicIcon css={css} {...iconProps} />;
};

Icon.displayName = 'Icon';

export default Icon;
