import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  faChevronDown,
  faChevronUp,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  logout,
  selectIsAuthenticated,
  selectUser,
} from '@store/auth/auth.slice';
import tw from 'twin.macro';

import Button from '@components/elements/Button';
import Link from '@components/elements/Link';
import ROUTE_URLS from '@constants/routeUrls';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useSwitch from '@hooks/useSwitch';
import { pushClickMenuGTMEvent } from '@utils/gtm';

const AuthButtons = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const ref = useRef();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { id: userId } = useSelector(selectUser);
  const { allowClientSeeMoneyBox, allowClientSeeOwnId } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );
  const [isOpenMenu, setOpenMenu, setClosedMenu] = useSwitch(false);
  useOnClickOutside(ref, setClosedMenu);

  if (isAuthenticated) {
    return (
      <div tw="relative mx-2" ref={ref}>
        <div
          tw="cursor-pointer flex items-center"
          onClick={isOpenMenu ? setClosedMenu : setOpenMenu}
          data-cy="my-account-dropdown"
        >
          <FontAwesomeIcon icon={faUserCircle} />
          <span tw="ml-3 mr-2">
            {t('$*components.header.myAccount', 'Moje konto')}
          </span>
          <FontAwesomeIcon
            icon={isOpenMenu ? faChevronUp : faChevronDown}
            tw="text-xs"
          />
        </div>
        {isOpenMenu && (
          <div tw="absolute px-3 py-4 bg-white shadow whitespace-nowrap right-0 top-14">
            {allowClientSeeOwnId && (
              <div tw="border-b border-gray-1 mb-3 pb-2" data-cy="id-dropdown">
                <span tw="font-bold">
                  {t('$*components.header.myAccount.id', 'ID')}:
                </span>{' '}
                {userId}
              </div>
            )}
            {allowClientSeeMoneyBox && (
              <div onClick={setClosedMenu} data-cy="moneybox-dropdown">
                <Link
                  data-cy="nav__moneybox"
                  href={ROUTE_URLS.MONEY_BOX}
                  styles={
                    router.asPath === ROUTE_URLS.MONEY_BOX
                      ? tw`font-semibold`
                      : ''
                  }
                  onClick={() => {
                    pushClickMenuGTMEvent({
                      text: t(
                        '$*components.header.myAccount.conditionMoneyBox',
                        '$$Stan skarbonki'
                      ),
                      action: 'dropdown menu',
                      level: 'sub1',
                    });
                  }}
                >
                  {t(
                    '$*components.header.myAccount.conditionMoneyBox',
                    'Stan skarbonki'
                  )}
                </Link>
              </div>
            )}
            <div onClick={setClosedMenu} data-cy="setting-account-dropdown">
              <Link
                href={ROUTE_URLS.ACCOUNT_SETTINGS}
                styles={
                  router.asPath === ROUTE_URLS.ACCOUNT_SETTINGS
                    ? tw`font-semibold`
                    : ''
                }
                onClick={() => {
                  pushClickMenuGTMEvent({
                    text: t(
                      '$*components.header.myAccount.settings',
                      '$$Ustawienia konta'
                    ),
                    action: 'dropdown menu',
                    level: 'sub1',
                  });
                }}
              >
                {t(
                  '$*components.header.myAccount.settings',
                  'Ustawienia konta'
                )}
              </Link>
            </div>
            <div
              tw="border-t border-gray-1 mt-3 pt-2"
              data-cy="logout-dropdown"
            >
              <Button
                variant="link"
                onClick={() => {
                  pushClickMenuGTMEvent({
                    text: t(
                      '$*components.header.button.logout',
                      '$$Wyloguj się'
                    ),
                    action: 'dropdown menu',
                    level: 'sub1',
                  });
                  dispatch(logout());
                  setClosedMenu();
                }}
              >
                {t('$*components.header.button.logout', 'Wyloguj się')}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div tw="flex items-center">
        <Link
          href={ROUTE_URLS.AUTH_LOGIN}
          styles={tw`mx-3`}
          data-cy="nav__login"
          onClick={() => {
            pushClickMenuGTMEvent({
              text: t('$*components.header.button.login', '$$Zaloguj się'),
            });
          }}
        >
          {t('$*components.header.button.login', '$$Zaloguj się')}
        </Link>
        <Link
          href={ROUTE_URLS.AUTH_REGISTER}
          styles={tw`mx-3`}
          type="button"
          variant="contained"
          data-cy="nav__register"
          onClick={() => {
            pushClickMenuGTMEvent({
              text: t(
                '$*components.header.button.register',
                '$$Zarejestruj się'
              ),
            });
          }}
        >
          {t('$*components.header.button.register', 'Zarejestruj się')}
        </Link>
      </div>
    );
  }
};

export default AuthButtons;
