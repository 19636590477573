import type { ReactNode } from 'react';

import DisplayOn from './DisplayOn';

type Props = {
  children: ReactNode;
};

const DisplayUpSm = ({ children }: Props) => (
  <DisplayOn size="sm" mode="up" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayUpSm.displayName = 'DisplayUpSm';

export default DisplayUpSm;
