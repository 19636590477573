import { useTranslation } from 'next-i18next';

import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectIsAuthenticated } from '@store/auth/auth.slice';
import tw from 'twin.macro';

import ROUTE_URLS from '@constants/routeUrls';
import {
  selectModuleConfigClientPanel,
  selectPanelOptions,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import { pushClickMenuGTMEvent } from '@utils/gtm';

const useNavItems = (isMobile = false) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showReferrerCode = false } = useAppConfigSelector(selectPanelOptions);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { allowClientSeeMoneyBox } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const navItems = useMemo(() => {
    return [
      {
        dataCy: 'nav__order',
        listItem: {
          css: tw`lg:mx-3`,
        },
        link: {
          href: ROUTE_URLS.NEW_ORDER_FORM,
          label: t('$*common.order', 'Zamów'),
          isProtected: false,
          ...(isAuthenticated && !isMobile
            ? { type: 'button', variant: 'contained', color: 'primary' }
            : {}),
          onClick: () => {
            pushClickMenuGTMEvent({
              text: t('$*components.header.nav.order', '$$Zamów'),
            });
          },
        },
      },
      {
        dataCy: 'nav__my_order',
        listItem: { css: tw`lg:mx-3` },
        link: {
          href: ROUTE_URLS.MY_ORDERS,
          label: t('$*components.header.nav.myOrders', 'Moje zamówienia'),
          isProtected: true,
          onClick: () => {
            pushClickMenuGTMEvent({
              text: t('$*components.header.nav.myOrders', '$$Moje zamówienia'),
            });
          },
        },
      },
      {
        dataCy: 'nav__bmi',
        listItem: { css: tw`lg:mx-3` },
        link: {
          href: ROUTE_URLS.BMI_CALCULATOR,
          label: t('$*components.header.nav.myBmi', 'Moje BMI'),
          isProtected: true,
        },
      },
      {
        dataCy: 'nav__history',
        listItem: { css: tw`lg:mx-3` },
        link: {
          href: ROUTE_URLS.HISTORY_ORDERS,
          label: t(
            '$*components.header.nav.historyOrders',
            'Historia zamówień'
          ),
          isProtected: true,
          onClick: () => {
            pushClickMenuGTMEvent({
              text: t(
                '$*components.header.nav.historyOrders',
                '$$Historia zamówień'
              ),
            });
          },
        },
      },
      ...(showReferrerCode
        ? [
            {
              dataCy: 'nav__share_program',
              listItem: { css: tw`lg:mx-3` },
              link: {
                href: ROUTE_URLS.SHARE_PROGRAM,
                label: t(
                  '$*components.header.nav.shareProgram',
                  'Kod polecający'
                ),
                isProtected: true,
              },
              onClick: () => {
                pushClickMenuGTMEvent({
                  text: t(
                    '$*components.header.nav.shareProgram',
                    '$$Kod polecający'
                  ),
                });
              },
            },
          ]
        : []),
      ...(isMobile && !isAuthenticated
        ? [
            {
              dataCy: 'nav__login-button',
              listItem: {},
              link: {
                href: ROUTE_URLS.AUTH_LOGIN,
                label: t('$*components.header.button.login', 'Zaloguj się'),
                isProtected: false,
              },
              onClick: () => {
                pushClickMenuGTMEvent({
                  text: t('$*components.header.button.login', '$$Zaloguj się'),
                });
              },
            },
            {
              dataCy: 'nav__register-button',
              listItem: {},
              link: {
                href: ROUTE_URLS.AUTH_REGISTER,
                label: t(
                  '$*components.header.button.register',
                  'Zarejestruj się'
                ),
                isProtected: false,
                onClick: () => {
                  pushClickMenuGTMEvent({
                    text: t(
                      '$*components.header.button.register',
                      '$$Zarejestruj się'
                    ),
                  });
                },
              },
            },
          ]
        : []),
      ...(isMobile
        ? [
            ...(allowClientSeeMoneyBox
              ? [
                  {
                    dataCy: 'nav__condition-moneybox',
                    listItem: {},
                    link: {
                      href: ROUTE_URLS.MONEY_BOX,
                      label: t(
                        '$*components.header.myAccount.conditionMoneyBox',
                        'Stan skarbonki'
                      ),
                      isProtected: true,
                      onClick: () => {
                        pushClickMenuGTMEvent({
                          text: t(
                            '$*components.header.myAccount.conditionMoneyBox',
                            '$$Stan skarbonki'
                          ),
                        });
                      },
                    },
                  },
                ]
              : []),
            {
              dataCy: 'nav__settings',
              listItem: {},
              link: {
                href: ROUTE_URLS.ACCOUNT_SETTINGS,
                label: t(
                  '$*components.header.myAccount.settings',
                  'Ustawienia konta'
                ),
                isProtected: true,
                onClick: () => {
                  pushClickMenuGTMEvent({
                    text: t(
                      '$*components.header.myAccount.settings',
                      '$$Ustawienia konta'
                    ),
                  });
                },
              },
            },
            {
              dataCy: 'nav__logout',
              listItem: {},
              link: {
                href: ROUTE_URLS.AUTH_LOGIN,
                label: t('$*components.header.button.logout', 'Wyloguj się'),
                isProtected: true,
                onClick: () => {
                  pushClickMenuGTMEvent({
                    text: t(
                      '$*components.header.button.logout',
                      '$$Wyloguj się'
                    ),
                  });

                  dispatch(logout());
                },
              },
            },
          ]
        : []),
    ].filter(Boolean);
  }, [t, showReferrerCode, isAuthenticated]);

  return navItems;
};

export default useNavItems;
