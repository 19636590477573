import type { ReactNode } from 'react';

import DisplayOn from './DisplayOn';

type Props = {
  children: ReactNode;
};

const DisplayUpMd = ({ children }: Props) => (
  <DisplayOn size="md" mode="up" defaultState={true}>
    {children}
  </DisplayOn>
);

DisplayUpMd.displayName = 'DisplayUpMd';

export default DisplayUpMd;
