import { useRouter } from 'next/router';
import { i18n } from 'next-i18next';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from '@store/auth/auth.actions';
import { selectIsAuthenticated } from '@store/auth/auth.slice';
import { getCookie, setCookie } from 'cookies-next';

import TIME from '@constants/time';
import useLanguages from '@hooks/useLanguages';
import useLocale from '@hooks/useLocale';

const withLanguageSelector = Component => {
  const LanguageSelector = props => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const { data: apiLanguages = [] } = useLanguages({ enabled: false });
    const languages = apiLanguages.map(({ id, isoCode, image, name }) => {
      return { flagCode: isoCode, id, image, label: name, value: isoCode };
    });

    const router = useRouter();
    const { locale } = useLocale();
    const initialLanguage =
      languages.find(({ value }) => value === locale) ?? {};
    const [language, setLanguage] = useState(initialLanguage);

    useEffect(() => {
      const currentNextLocale = getCookie('NEXT_LOCALE');

      if (currentNextLocale !== locale) {
        isAuthenticated && dispatch(updateUserData({ language: locale }));
        window.__localeId__ = locale;
        setCookie('NEXT_LOCALE', locale, { maxAge: TIME.MONTH_IN_SECONDS });
      }
    }, []);

    const changeLanguage = async languageOption => {
      const { pathname, asPath, query } = router;
      router.push({ pathname, query }, asPath, {
        locale: languageOption.value,
      });

      setLanguage(languageOption);
      isAuthenticated &&
        dispatch(updateUserData({ language: languageOption.value }));

      window.__localeId__ = languageOption.value;
      setCookie('NEXT_LOCALE', languageOption.value, {
        maxAge: TIME.MONTH_IN_SECONDS,
      });

      i18n.changeLanguage(languageOption.value);
    };

    const allProps = {
      ...props,
      changeLanguage,
      language,
      languages,
    };

    return <Component {...allProps} />;
  };

  LanguageSelector.displayName = 'LanguageSelector';
  return LanguageSelector;
};

export default withLanguageSelector;
