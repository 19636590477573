import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { useSelector } from 'react-redux';
import { selectIsNativeApp } from '@store/nativeAppConfig/nativeAppConfig.slice';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';
import { styled } from 'twin.macro';

import Image from '@components/elements/Image/Image';
import ImageWrapper from '@components/elements/Image/ImageWrapper';
import ROUTE_URLS from '@constants/routeUrls';
import {
  selectBranding,
  selectModuleLinks,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useMediaQuery from '@hooks/useMediaQuery';
import { pushLogoGTMEvent } from '@utils/gtm';
import { checkIsUrlExternal } from '@utils/helpers';
import { up } from '@utils/screens';

const Logo = ({ maxWidth = 'auto', onLoadLogo = () => {}, styles = {} }) => {
  const router = useRouter();
  const { logos = {} } = useAppConfigSelector(selectBranding);
  const links = useAppConfigSelector(selectModuleLinks);
  const logoLink = links?.logo?.link || ROUTE_URLS.HOME;
  const isUpLg = useMediaQuery(up('lg'), true);
  const isNativeApp = useSelector(selectIsNativeApp);

  if (isEmpty(logos)) {
    return null;
  }

  if (isNativeApp) {
    return (
      <span
        className={clsx('logo__link', isUpLg ? '' : 'is-mobile')}
        css={styles?.a}
        tw="md:mr-6 inline-block"
      >
        <LogoImage maxWidth={maxWidth} onLoadLogo={onLoadLogo} />
      </span>
    );
  }

  if (checkIsUrlExternal(logoLink)) {
    return (
      <a
        className={clsx('logo__link', isUpLg ? '' : 'is-mobile')}
        css={styles?.a}
        href={logoLink}
        target={links?.logo?.openNewCard ?? false ? '_blank' : '_self'}
        rel="noopener noreferrer"
        tw="md:mr-6 inline-block"
      >
        <LogoImage maxWidth={maxWidth} onLoadLogo={onLoadLogo} />
      </a>
    );
  }

  return (
    <NextLink href={logoLink} locale={router?.locale} legacyBehavior>
      <a
        className={clsx('logo__link', isUpLg ? '' : 'is-mobile')}
        css={styles?.a}
        tw="md:mr-6 inline-block"
        onClick={() => {
          pushLogoGTMEvent(logoLink);
        }}
      >
        <LogoImage maxWidth={maxWidth} onLoadLogo={onLoadLogo} />
      </a>
    </NextLink>
  );
};

const LogoImageWrapper = styled(ImageWrapper)`
  img {
    ${tw`!w-auto !max-h-14`};
  }
`;

const LogoImage = ({ maxWidth, onLoadLogo }) => {
  const { logos = {} } = useAppConfigSelector(selectBranding);
  const isUpLg = useMediaQuery(up('lg'), true);

  return (
    <LogoImageWrapper
      className={clsx('logo__wrapper', isUpLg ? '' : 'is-mobile')}
      style={{ maxWidth }}
    >
      <Image
        className={clsx('logo__image', isUpLg ? '' : 'is-mobile')}
        src={isUpLg ? logos.desktop : logos.mobile}
        alt="Logo"
        layout="fill"
        onLoad={onLoadLogo}
      />
    </LogoImageWrapper>
  );
};

Logo.displayName = 'Logo';

export default Logo;
