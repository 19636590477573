import { styled } from 'twin.macro';

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;

  > span {
    position: unset !important;
  }

  img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
`;

export default ImageWrapper;
