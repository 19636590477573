import type { Branding } from '@tsTypes/api/Branding';

import { pushClickMenuGTMEvent } from '@utils/gtm';

type LowerLinksProps = {
  links: NonNullable<Branding['footerLink']>['down'];
};

const LowerLinks = ({ links }: LowerLinksProps) => {
  const hasAnyLinkVisible = links.some(({ name }) => !!name);

  if (!hasAnyLinkVisible) {
    return null;
  }

  return (
    <ul tw="flex flex-wrap justify-center items-center pt-5 xl:pt-0 xl:justify-end">
      {links
        .filter(({ name }) => !!name)
        .map(({ name, link, icon }, index) => (
          <li tw="m-2.5" key={`${name}${index}`}>
            <a
              tw="ml-8 flex items-center text-black"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                pushClickMenuGTMEvent({
                  text: name,
                });
              }}
            >
              {!!icon && (
                <img
                  tw="w-[30px] h-[30px] mr-3.5 inline-block"
                  src={icon}
                  alt=""
                />
              )}
              {!!name && <p>{name}</p>}
            </a>
          </li>
        ))}
    </ul>
  );
};

export default LowerLinks;
