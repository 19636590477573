import Select from '@components/elements/Form/Select';

import customStyles from './customStyles';
import LanguageOptionLabel from './LanguageOptionLabel';
import withLanguageSelector from './withLanguageSelector';

const LanguageSelector = ({ languages, language, changeLanguage }) => {
  return (
    <Select
      options={languages}
      value={language}
      getOptionLabel={LanguageOptionLabel}
      onChange={item => changeLanguage(item)}
      styles={customStyles}
      isSearchable={false}
    />
  );
};

LanguageSelector.displayName = 'LanguageSelector';

export default withLanguageSelector(LanguageSelector);
