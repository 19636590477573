import { useState } from 'react';

const useSwitch = initialState => {
  const [switchState, setSwitchState] = useState(initialState);
  const switchOn = () => setSwitchState(true);
  const switchOff = () => setSwitchState(false);

  return [switchState, switchOn, switchOff];
};
export default useSwitch;
