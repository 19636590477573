import { useTranslation } from 'next-i18next';

import { Fragment, useState } from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import kebabCase from 'lodash/kebabCase';
import { theme } from 'twin.macro';

import useMobileDetect from '@hooks/useMobileDetect';
import { isClient } from '@utils/helpers';

const InputSelect = ({
  name,
  options,
  value,
  isOpenedOnInit = false,
  onBlur = () => {},
  onChange,
  keepClosed = false,
  styles = null,
  mergeStyles = {},
  disabled = false,
  customDisabledStyles = {},
  bottomLabelTxt = '',
  useNativeSelect = false,
  onAfterFocus = () => {},
  dataCy = '',
  ...restProps
}) => {
  const { t } = useTranslation();
  const [isSelectOpened, setIsSelectOpened] = useState(isOpenedOnInit);
  const customStyles = styles || {
    control: (provided, { isDisabled }) => ({
      ...provided,
      padding: '4px 0 4px 20px',
      background: isDisabled
        ? customDisabledStyles?.background ?? theme`colors.gray.1`
        : provided.background,
      'border-color': isDisabled
        ? customDisabledStyles?.borderColor ?? theme`colors.gray.1`
        : provided['border-color'],
      cursor: isDisabled ? 'not-allowed' : provided.cursor,
    }),
    input: provided => ({
      ...provided,
      input: {
        '--tw-ring-shadow': 'transparent !important',
      },
    }),
    valueContainer: provided => ({
      ...provided,
      padding: 0,
    }),
    singleValue: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? theme`colors.gray.3` : provided.color,
    }),
    placeholder: provided => ({
      ...provided,
      margin: 0,
    }),
    indicatorSeparator: (provided, { isDisabled }) => ({
      ...provided,
      'background-color': isDisabled
        ? theme`colors.gray.2`
        : provided['background-color'],
    }),
    dropdownIndicator: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled ? theme`colors.gray.2` : provided.color,
    }),
    menuList: provided => ({
      ...provided,
      background: theme`colors.white`,
      zIndex: '9999',
    }),
    option: provided => ({
      ...provided,
      padding: '8px 20px',
      color: theme`colors.primary`,
      background: theme`colors.white`,
      '&:hover': {
        cursor: 'pointer',
        color: theme`colors.white`,
        background: theme`colors.primary`,
      },
    }),
    menuPortal: base => ({ ...base, zIndex: 99 }),
    ...mergeStyles,
  };

  const onInputChange = (options, { action }) => {
    if (action === 'menu-close') {
      setIsSelectOpened(false);
    }
  };

  const onFocus = () => {
    setIsSelectOpened(true);
    onAfterFocus();
  };

  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();

  if (isMobile && useNativeSelect) {
    return (
      <div tw="relative w-full">
        <select
          className={`select-native--${kebabCase(name)}`}
          tw="absolute opacity-0 w-full h-full z-10 whitespace-normal break-words"
          name={name}
          onChange={event => {
            if (onChange) {
              const selectedOption = options.find(
                ({ value }) => value == event.target.value
              );

              onChange(selectedOption);
            }
          }}
          {...restProps}
        >
          <option value="" disabled selected>
            {t(
              '$*components.form.select.nativeSelectFirstOption',
              'Wybierz...'
            )}
          </option>
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <Select
          className={`select--${kebabCase(name)}`}
          isSearchable={false}
          options={options}
          value={value}
          name={name}
          blurInputOnSelect
          menuIsOpen={keepClosed ? false : isSelectOpened || undefined}
          menuPortalTarget={isClient && document.querySelector('#selectPortal')}
          isDisabled={disabled}
          {...restProps}
          styles={customStyles}
          theme={defaultTheme => ({
            ...defaultTheme,
            colors: {
              ...defaultTheme.colors,
              primary25: theme`colors.primary-hover`,
              primary: theme`colors.primary`,
              primary50: theme`colors.primary-hover`,
              neutral50: 'red',
            },
          })}
        />
        {bottomLabelTxt && <p tw="text-sm">{bottomLabelTxt}</p>}
      </div>
    );
  }

  return (
    <Fragment>
      <Select
        className={`select--${kebabCase(name)}`}
        isSearchable={false}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        value={value}
        name={name}
        onFocus={onFocus}
        blurInputOnSelect
        onInputChange={onInputChange}
        menuIsOpen={keepClosed ? false : isSelectOpened || undefined}
        menuPortalTarget={isClient && document.querySelector('#selectPortal')}
        isDisabled={disabled}
        {...restProps}
        styles={customStyles}
        components={
          dataCy && {
            Control: props => (
              <components.Control
                {...props}
                innerProps={{
                  ...props.innerProps,
                  'data-cy': dataCy,
                }}
              />
            ),
          }
        }
        theme={defaultTheme => ({
          ...defaultTheme,
          colors: {
            ...defaultTheme.colors,
            primary25: theme`colors.primary-hover`,
            primary: theme`colors.primary`,
            primary50: theme`colors.primary-hover`,
            neutral50: 'red',
          },
        })}
      />
      {bottomLabelTxt && <p tw="text-sm">{bottomLabelTxt}</p>}
    </Fragment>
  );
};

InputSelect.displayName = 'InputSelect';

export default InputSelect;
