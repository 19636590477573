import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import useAppConfig from '@hooks/useAppConfig';

import usePaymentsData from './hooks/usePaymentsData';
import LowerLinks from './LowerLinks/LowerLinks';
import UpperLinks from './UpperLinks/UpperLinks';

const Footer = () => {
  const { t } = useTranslation();

  const {
    data: {
      branding: { name: brandName, footerLink, showFooterCaterings },
    },
  } = useAppConfig();
  const paymentData = usePaymentsData();

  return (
    <footer id="footer" tw="px-5 py-2 mt-auto bg-white border-t border-gray-1">
      <div className="container">
        <div tw="grid grid-cols-1 xl:grid-cols-2 border-b pb-5 pt-5 text-sm">
          <p tw="text-center xl:text-left m-0 p-0">
            {t('$*footer.footerLink.up.LeftNew', {
              brandName,
            })}
          </p>
          {footerLink?.up && <UpperLinks links={footerLink.up} />}
        </div>
        <div
          className="row"
          css={[
            tw`grid grid-cols-1 mt-5 text-sm xl:grid-cols-3`,
            showFooterCaterings ? tw`xl:grid-cols-3` : tw`xl:grid-cols-2`,
          ]}
        >
          <ul tw="flex gap-x-3 gap-y-1 flex-wrap justify-center xl:justify-start">
            {paymentData.map(({ name, src }) => (
              <li key={name}>
                <img src={src} alt={name} />
              </li>
            ))}
          </ul>
          {showFooterCaterings && (
            <a
              tw="flex items-center justify-center pt-3 xl:pt-0"
              href="https://www.caterings.pl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/footer/caterings logo.svg"
                alt={`${t('$*footer.footerLink.down.center')} Caterings`}
              />
            </a>
          )}
          {footerLink?.down && <LowerLinks links={footerLink.down} />}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
