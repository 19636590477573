import { theme } from 'twin.macro';

const customStyles = {
  control: () => ({
    display: 'flex',
    padding: '0',
    minWidth: '90px',
    cursor: 'pointer',
  }),
  dropdownIndicator: provided => ({ ...provided, padding: '8px 0' }),
  indicatorSeparator: () => ({}),
  option: provided => ({
    ...provided,
    padding: '8px 10px',
    '&:hover': {
      cursor: 'pointer',
      color: theme`colors.white`,
    },
  }),
  menuPortal: base => ({ ...base, zIndex: 99 }),
};

export default customStyles;
